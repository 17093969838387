/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://6qpzn34zmrfwzp6mqukoxossma.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-lbywyxvpeffebljnwdg243jkum",
    "aws_cognito_identity_pool_id": "us-east-1:ec0b6178-6258-4ac5-982c-c1aef59f356d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_6qZNRaQ03",
    "aws_user_pools_web_client_id": "3qjthe3jfupiuvk93shjqdilcm",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PREFERRED_USERNAME",
        "EMAIL",
        "GIVEN_NAME",
        "FAMILY_NAME"
    ],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 12,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ]
};


export default awsmobile;
